import {doGet, doPost} from "./config";

const login = (body) => {
    return doPost('/auth/login', body)
};

const logout = (body) => {
    return doGet('/auth/logout')
};

const checkPincode = (body) => {
    return doPost('/auth/check-pincode', body)
};

const canCheckPincode = () => {
    return doGet('/auth/can-check-pincode')
};

const checkAuth = () => {
    return doGet('/auth/check')
};

const resendPincode = () => {
    return doGet('/auth/resend-pincode')
};

const listApps = () => {
    return doGet('/auth/apps')
};

export default {
    logout,
    login,
    canCheckPincode,
    checkPincode,
    resendPincode,
    checkAuth,
    listApps
};
