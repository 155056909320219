import metadata from 'src/scripts/metadata.json';

export const APP_VERSION = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const REGEXPS = {
    PWD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.*){8,}$/
};


export const sortOptions = [
    {
        value: 'updatedAt|desc',
        label: 'Last update (newest first)'
    },
    {
        value: 'updatedAt|asc',
        label: 'Last update (oldest first)'
    },
    {
        value: 'orders|desc',
        label: 'Total orders (high to low)'
    },
    {
        value: 'orders|asc',
        label: 'Total orders (low to high)'
    }
];


export const ALERTVALUES = [
    {
        id: 'GTE',
        text: 'Maggiore di'
    },
    {
        id: 'LTE',
        text: 'Minore di'
    },
    {
        id: 'EQ',
        text: 'Uguale a'
    }
];

